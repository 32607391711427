import React from "react"
import Layout from "../components/layout"
import { Col, Container, Row } from "reactstrap"
import Header from "../components/header"
import Card from "reactstrap/es/Card"
import CardBody from "reactstrap/es/CardBody"
import Media from "reactstrap/es/Media"
import FeatherIcon from "feather-icons-react"
import SEO from "../components/SEO/seo"
import { getTrialDays } from "../assets/javascript/affiliate"

const Contact = () => {
  return (
    <Layout>
      <SEO
        title={"Vous n'êtes plus seul pour gérer votre comptabilité"}
        desc={"Nous prenons soin de nos utilisateurs, c'est pour cette raison que nous sommes disponibles 7j/7 pour répondre à toutes vos questions. Testez Bizyness gratuitement pendant 15 jours !"}
      />
      <Header title={"Restons en contact !"}/>
      <section className="section pt-5 mt-4">
        <Container>
          <Row className="align-items-center">
            <Col
              lg={5}
              md={{ size: 6, order: 1 }}
              xs={{ order: 2 }}
              className="mt-4 mt-sm-0 pt-2 pt-sm-0"
            >
              <Card className="custom-form rounded shadow border-0">
                <CardBody>
                  <div id="message"></div>
                  <form
                    method="post"
                    action="https://formspree.io/mrgyazjn"
                    name="contact-form"
                    id="contact-form"
                  >
                    <input
                      type="hidden"
                      name="_next"
                      value="https://www.bizyness.fr/contact"
                    />
                    <input type="hidden" name="_language" value="fr"/>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group position-relative">
                          <label>
                            Nom <span className="text-danger">*</span>
                          </label>
                          <i>
                            <FeatherIcon
                              icon="user"
                              className="fea icon-sm icons"
                            />
                          </i>
                          <input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control pl-5"
                            placeholder="Votre Nom :"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group position-relative">
                          <label>
                            Email <span className="text-danger">*</span>
                          </label>
                          <i>
                            <FeatherIcon
                              icon="mail"
                              className="fea icon-sm icons"
                            />
                          </i>
                          <input
                            name="_replyto"
                            id="email"
                            type="email"
                            className="form-control pl-5"
                            placeholder="Votre Email :"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group position-relative">
                          <label>
                            Message <span className="text-danger">*</span>
                          </label>
                          <i>
                            <FeatherIcon
                              icon="message-circle"
                              className="fea icon-sm icons"
                            />
                          </i>
                          <textarea
                            name="comments"
                            id="comments"
                            rows="4"
                            className="form-control pl-5"
                            placeholder="Votre Message :"
                            required
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <input
                          type="submit"
                          id="submit"
                          name="send"
                          className="submitBnt btn btn-primary btn-block"
                          value="Envoyer"
                        />
                        <div id="simple-msg"></div>
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>

            <Col lg={7} md={{ size: 6, order: 2 }} xs={{ order: 1 }}>
              <div className="title-heading ml-lg-4">
                <h4 className="mb-4">Une question ? Un commentaire ?</h4>
                <p className="text-muted">
                  Utilisez le formulaire de contact ci-contre et vous recevrez une réponse rapidement. Sinon, le meilleur
                  moyen de
                  répondre à vos questions reste de tester{" "}
                  <span className="text-primary font-weight-bold">
                    Bizyness
                  </span>
                  , vous avez {getTrialDays()} jours pour le faire, et c'est sans engagement !
                </p>
                <Media className="contact-detail align-items-center mt-3">
                  <div className="icon">
                    <i>
                      <FeatherIcon
                        icon="mail"
                        className="fea icon-m-md text-dark mr-3"
                      />
                    </i>
                  </div>
                  <Media body className="content">
                    <h4 className="title font-weight-bold mb-0">Contact</h4>
                    <a
                      href="mailto:contact@bizyness.fr"
                      className="text-primary"
                    >
                      contact@bizyness.fr
                    </a>
                  </Media>
                </Media>

                <Media className="contact-detail align-items-center mt-3">
                  <div className="icon">
                    <i>
                      <FeatherIcon
                        icon="help-circle"
                        className="fea icon-m-md text-dark mr-3"
                      />
                    </i>
                  </div>
                  <Media body className="content">
                    <h4 className="title font-weight-bold mb-0">Support</h4>
                    <a
                      href="mailto:support@bizyness.fr"
                      className="text-primary"
                    >
                      support@bizyness.fr
                    </a>
                  </Media>
                </Media>

                <Media className="contact-detail align-items-center mt-3">
                  <div className="icon">
                    <i>
                      <FeatherIcon
                        icon="phone"
                        className="fea icon-m-md text-dark mr-3"
                      />
                    </i>
                  </div>
                  <Media body className="content">
                    <h4 className="title font-weight-bold mb-0">Téléphone</h4>
                    <a
                      href="tel:0458101949"
                      className="text-primary"
                    >
                      04 58 10 19 49
                    </a>
                  </Media>
                </Media>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default Contact
